import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface loadLogsByPageParam {
    current?:any
    size?:any
    startTime?:any
    endTime?:any
    keyword?:any
}
// -------接口列------

/**
 * [后台]API分页
 * @notes 
 */
export function loadLogsByPage(param:loadLogsByPageParam) {
    return get('/uaa/userAuthLogs/loadLogsByPage', param);
}

