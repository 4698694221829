
import { list } from '@/api/system/serviceModule'
import { loadLogsByPage } from '@/api/uaa/userAuthLogs'
import JtDatePicker from '@/components/JtDatePicker/index.vue';
import JtPagination from '@/components/JtPagination/index.vue';
import { Component,Vue, Watch } from 'vue-property-decorator'
  

@Component({
    name: 'RequestLogs',
    components: {
      JtPagination,
      JtDatePicker
    }
})
export default class extends Vue {
  tableKey :any
  listLoading = true
  total = 0
  list = []
  listQuery = {
    current: 1,
    size: 10,
    startTime: null,
    keyword: null,
    endTime: null
  }

  mounted() {
      this.getList()
  }
  //初始加载数据
  handleFilter() {
    this.listQuery.current = 1
    this.getList()
  }

  //加载api数据
  getList(){
    this.listLoading = true
    loadLogsByPage(this.listQuery).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      }).catch(()=>{
        this.listLoading = false
      })
  }

  @Watch("timeValue")
  watchDateTimePick(val:any){
    this.listQuery.startTime = val[0];
    this.listQuery.endTime = val[1];
  }
}
